import { useTranslation } from 'next-i18next';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import { useToolbarMoreButtonDot } from '@app/web/src/hooks/utils/useToolbarMoreButtonDot';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';
import { ResponsiveToolbar } from '@lib/web/ui';

export default function ClubToolbar() {
  const { hasFilter } = useFilter();
  const { hasSort } = useSort();
  const { toggleRightPanel, isTargetPanelOpened } =
    useBaseLayout<GlobalPanelParams>();
  const { t } = useTranslation();
  const commonToolbarItems = useCommonToolbarItems({
    enableSearch: true,
    searchPlaceholder: t('search.placeholder_club', 'Search club...'),
  });
  const { moreButtonDot } = useToolbarMoreButtonDot();

  return (
    <ResponsiveToolbar
      items={[
        {
          type: 'Filter',
          onClick: () => {
            toggleRightPanel(GlobalPanelKeys.GlobalFilter);
          },
          selected: isTargetPanelOpened(GlobalPanelKeys.GlobalFilter),
          highlight: hasFilter,
        },
        {
          type: 'Sort',
          onClick: () => {
            toggleRightPanel(GlobalPanelKeys.GlobalSort);
          },
          selected: isTargetPanelOpened(GlobalPanelKeys.GlobalSort),
          highlight: hasSort,
        },
        ...commonToolbarItems,
      ]}
      moreButtonDot={moreButtonDot}
    />
  );
}
